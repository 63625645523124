/* Flex container */

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.items-center {
  align-items: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.flex-col {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

/* Flex children */

.flex-1 {
  flex: 1;
}

.self-end {
  align-self: flex-end;
}

/* Visibility */

.hidden {
  visibility: hidden;
}

.visible {
  visibility: visible;
}

/* Position */

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.left-0 {
  left: 0;
}

.-left-3 {
  left: -0.75rem;
}

.top-0 {
  top: 0;
}

.top-3 {
  top: 0.75rem;
}

.top-4 {
  top: 1rem;
}

.top-16 {
  top: 4rem;
}

.top-17 {
  top: 4.25rem;
}

.-top-3 {
  top: -0.75rem;
}

.bottom-0 {
  bottom: 0;
}

.bottom-3 {
  bottom: 0.75rem;
}

.right-0 {
  right: 0;
}

.right-0\.5 {
  right: 0.125rem;
}

.right-1 {
  right: 0.25rem;
}

.right-1\.5 {
  right: 0.375rem;
}

.right-3 {
  right: 0.75rem;
}

.-right-3 {
  right: -0.75rem;
}

/* Font weight */

.font-bold {
  font-weight: bold;
}

/* Font size */

.text-xs {
  font-size: 0.75rem;
}

.text-lg {
  font-size: 1.125rem;
}

.text-4xl {
  font-size: 2.25rem;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

/* Padding */

.p-0 {
  padding: 0;
}

.p-0\.5 {
  padding: 0.125rem;
}

.p-1 {
  padding: 0.25rem;
}

.p-2 {
  padding: 0.5rem;
}

.p-4 {
  padding: 1rem;
}

/* Padding-x */

.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

/* Padding-y */

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

/* Padding left */

.pl-8 {
  padding-left: 2rem;
}

/* Padding top */

.pt-4 {
  padding-top: 1rem;
}

.pt-16 {
  padding-top: 4rem;
}

/* Padding right */

.pr-4 {
  padding-right: 1rem;
}

/* Padding bottom */

.pb-1 {
  padding-bottom: 0.25rem;
}

.pb-2 {
  padding-bottom: 0.5rem;
}

/* Margin */

.m-0 {
  margin: 0;
}

/* Margin-x */

/* Margin-y */

.my-0\.5 {
  margin-top: 0.125rem;
  margin-bottom: 0.125rem;
}

.my-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

/* Margin-left */

.ml-1 {
  margin-left: 0.25rem;
}

.ml-2 {
  margin-left: 0.5rem;
  word-break: break-word;
}

.ml-4 {
  margin-left: 1rem;
}

.ml-6 {
  margin-left: 1.5rem;
}

.ml-8 {
  margin-left: 2rem;
}

.ml-16 {
  margin-left: 4rem;
}

/* Margin-top */

.mt-0 {
  margin-top: 0;
}

.mt-1 {
  margin-top: 0.25rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mt-3 {
  margin-top: 0.75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mt-16 {
  margin-top: 4rem;
}

/* Margin-right */

.mr-1 {
  margin-right: 0.25rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.mr-4 {
  margin-right: 1rem;
}

/* Margin-bottom */

.mb-0\.5 {
  margin-bottom: 0.125rem;
}

.mb-1 {
  margin-bottom: 0.25rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.mb-3 {
  margin-bottom: 0.75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mb-16 {
  margin-bottom: 4rem;
}

/* Width */

.w-full {
  width: 100%;
}

/* Height */

.h-full {
  height: 100%;
}

/* Text color */

.text-red {
  color: #ff0000;
}

.text-white {
  color: rgba(255, 255, 255, 1);
}

.text-current {
  color: currentColor;
}

/* Text alignment */

.text-center {
  text-align: center;
}

.text-green {
  color: green;
}

/* Border */

.border-none {
  border-style: none;
}

/* background color */

.bg-transparent {
  background-color: transparent;
}

/* cursor */

.cursor-pointer {
  cursor: pointer;
}

.no-underline {
  text-decoration: none;
}

/* Line height */

.leading-tight {
  line-height: 1.25;
}

.leading-normal {
  line-height: 1.5;
}
